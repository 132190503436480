import React from 'react';
// import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { navigate } from 'gatsby';


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(24),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function RegisterForm() {
    const classes = useStyles();
    const handleSubmit = (event) => {
        event.preventDefault()
        let url = `https://${process.env.GATSBY_AIRTABLE_URL}`;
        console.log("URL", url)
        const newGroup = {
            "records": [
                {
                    "fields": {
                        "Group Name": `${event.target.groupName.value}`,
                        "City": `${event.target.city.value}`,
                        "State": `${event.target.state.value}`,
                        "Organizer LinkedIn": `${event.target.oLinkedIn.value}`,
                        "Organizer Name": `${event.target.oName.value}`,
                        "Url": `${event.target.url.value}`
                    }
                }
            ]
        };

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.GATSBY_AIRTABLE_API_KEY}`
            },
            body: JSON.stringify(newGroup)
        });
        
        navigate('/')

    }


    return (
        <Container maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                {/* <Avatar className={classes.avatar}>
                    <ExploreIcon />
                </Avatar> */}
                <Typography component="h1" variant="h5">
                    Register
        </Typography>

        
                <form className={classes.form} noValidate={false} onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>

                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                validate={'required'}
                                id="groupName"
                                label="Group Name"
                                name="groupName"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete="city"
                                name="city"
                                variant="outlined"
                                required
                                validate={'required'}
                                fullWidth
                                id="city"
                                label="City"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                validate={'required'}
                                fullWidth
                                id="state"
                                label="State"
                                name="state"
                                autoComplete="state"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                validate={'required'}
                                fullWidth
                                id="oLinkedIn"
                                label="Organizer LinkedIn"
                                name="oLinkedIn"
                            // autoComplete="email"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                validate={'required'}
                                fullWidth
                                id="oName"
                                label="Organizer Name"
                                name="oName"
                            // autoComplete="email"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                validate={'required'}
                                fullWidth
                                id="url"
                                label="Event Group Url (Meetup, Eventbrite, etc.)"
                                name="url"
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Sign Up
          </Button>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Link href="/" variant="body2">
                                Already registered your group? Home
              </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            {/* Box */}
            {/* <Box mt={5}>
      </Box> */}
        </Container>
    );
}