import React from 'react';
import Container from '@material-ui/core/Container';
import RegisterForm from '../components/RegisterForm';
import Newsletter from "../containers/Newsletter/index"
import Header from '../components/header'
import Footer from '../components/Footer';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';


export default function register() {
    return (
        <React.Fragment>
        <CssBaseline />
        <main>
          <Header siteTitle={'Cassandra Network'} />
          <Container maxWidth="md" style={{ "marginTop": "50px" }}>
            <Typography component="h2" variant="h3" align="center" color="textPrimary" gutterBottom>
              The Apache® Cassandra™ Network
          </Typography>
          </Container>
          <RegisterForm />
          <Newsletter />
          <Footer />
        </main>
      </React.Fragment>
    )
}
